import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1638708424/tulum_yt5qic.png",
        "alt": "Tulum-Beth-Becerra"
      }}></img></p>
    <p>{`Sin dudarlo debo decir que el fenómeno cultural de Tulum me asombra. Este pequeño poblado pegado al mar en las costas de Quintana Roo tiene las historias más curiosas y extrañas en sus apretadas calles sin pavimentar. Es que Tulum tiene mucho de todo: puedes ver hermosas mujeres sumamente producidas en la zona de restaurantes y hoteles, donde es común que una mesa de bar llegue a costar 40 mil pesos en un día con evento de electro. Sin embargo, a unas cuantas calles se amontonan familias en cabañas maltrechas de materiales improvisados, -paracaidistas, les llaman-, estas personas de escasos recursos han llegado a tomar posesión de terrenos baldíos con la esperanza de, al menos, tener un terrenito donde armar un jacalito y pasar los días.`}</p>
    <p><strong parentName="p">{`Ah Tulum, con sus hermosas playas color turquesa, su arena fina, y sus filas interminables de migrantes europeos y argentinos (los últimos hasta venden “artesanías en las calles”, como auténticos nativos de la zona).`}</strong>{` No me malinterpreten, nada tengo en contra de los migrantes blanquitos del lugar, pero `}<strong parentName="p">{`me resulta curioso cómo se han concentrado gran parte de estos seres de “vibraciones altas” en un mismo lugar, que simplemente llamaría “mamador”.`}</strong>{` Sobre todo, porque impera la cultura del `}<em parentName="p">{`New Age`}</em>{`.`}</p>
    <p>{`Los temazcales y ceremonias de limpieza espiritual son ofertados al público casi siempre en dólares estadounidenses y en la noche podrás disfrutar del `}<em parentName="p">{`rave`}</em>{`, hasta el culo de tus drogas favoritas: la coca y los ácidos son sumamente fáciles de encontrar.`}</p>
    <p>{`También toparás hoteles hechos de palos de madera, cuya rústica experiencia puede costar 20 mil pesos la noche. Menos mal que la arquitectura con palos está hecha `}<em parentName="p">{`artísticamente`}</em>{`, sino tal cantidad se sentiría como un robo. Si la arquitectura rústica, selvática, alternativa y eco `}<em parentName="p">{`friendly`}</em>{` no te convence por el precio, piensa que seguramente alguna celebridad obscenamente rica de Hollywood se quedó en tal o cual lugar, así la experiencia puede ser más `}<em parentName="p">{`snob`}</em>{`, aún.`}</p>
    <p>{`No olvidemos los `}<em parentName="p">{`tours`}</em>{`, esos que son flexibles a los gustos y necesidades del público conocedor; cabría resaltar las grandes cantidades de turistas `}<em parentName="p">{`influencers`}</em>{` que llegan a nuestro bello destino. Los guías locales tienen que adaptarse a las necesidades de los clientes por lo que se ha creado un nuevo `}<em parentName="p">{`tour`}</em>{`, uno que te lleva de atracción turística a otra rápidamente con el objetivo expreso de tomar fotos en la locación, obviamente para las redes sociales, y partir a la siguiente. Esto es muy útil pues sigue alimentando la falsa imagen de las personas de tener “experiencias gratificantes” y de “disfrutar plenamente del mundo y los momentos”. Nada más falso, dudo que los 5 minutos en cada locación te permita apreciar realmente la riqueza natural de la zona.`}</p>
    <p><strong parentName="p">{`Entre tristeza por los contrastes clasistas de Tulum, risa por las prácticas banales y una profunda decepción de la cultura del turismo del país, escribo esta columna`}</strong>{`, reflexiono sobre este pequeño poblado, que inevitablemente crecerá hasta convertirse en una de las ciudades más cosmopolitas del país, pero a qué precio. `}<strong parentName="p">{`¿Cuándo cambiamos el folklor de la señora de las gorditas en la zona arqueológica, por un Hagen Däss y un Starbucks? “cultura mexicana” sin mexicanos, ni expresión mexicana. Queda la forma sin el fondo: una carcasa del México hermoso, culturalmente rico que todos conocemos, desgastándose en otra imagen de Frida Kahlo sobre un producto chino; una riqueza natural que sólo sirve para el momento de la foto; prácticas ancestrales menospreciadas por cualquiera que se diga chamán, para venderla a otro blanco`}</strong>{`, más grandes mansiones frente a chocitas de lámina; y personas, muchas personas por doquier, de todos lados del mundo, conviviendo en esta esfera un tanto surrealista, donde todo puede pasar.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      